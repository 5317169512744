import { Center } from '@chakra-ui/react';
import React from 'react';

function ContactUs() {
  return (
    <Center>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeNP7Ut9O7XATOP6F2KDmFBtbrqXy0a4XO27Of-iYjy9PGa5g/viewform?embedded=true"
        width="600"
        height="1200"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </Center>
  );
}

export default ContactUs;
