import { Center, Heading, Link, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';

function LandingPage() {
  useEffect(() => {}, []);

  return (
    <Center>
      <VStack spacing={12} p={12}>
        <Heading as="h1" size="2xl" color="teal.500">
          Launching Soon
        </Heading>
        {/* contact us component */}
        <Link href="/contact-us" fontSize="md">
          contact us
        </Link>
      </VStack>
    </Center>
  );
}

export default LandingPage;
