import React, { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

// import Footer from './Footer';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

function Layout() {
  useEffect(() => {}, []);

  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout;
