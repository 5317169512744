import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import TermsConditions from './TermsConditions';
import Privacy from './Privacy';
import ContactUs from './ContactUs';
import LandingPage from './LandingPage';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box fontSize="xl">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<LandingPage />} />
            <Route path="terms-and-conditions" element={<TermsConditions />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Route>
        </Routes>
      </Box>
    </ChakraProvider>
  );
}

export default App;
