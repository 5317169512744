import React, { useContext } from 'react';
import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import ColorModeSwitcher from './ColorModeSwitcher';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';

function NavBar() {
  const colorMode = useColorModeValue('light', 'dark');
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('login');
  };

  return (
    <Flex
      minWidth="max-content"
      alignItems="center"
      gap="2"
      p={2}
      background={colorMode === 'light' ? 'white' : 'gray.800'}
    >
      <Box ml={2}>
        <Link to="/">
          <Heading size="md">giftmaker</Heading>
        </Link>
      </Box>
      <Spacer />
      <ColorModeSwitcher />
    </Flex>
  );
}

export default NavBar;
