import {
  Box,
  ButtonGroup,
  Center,
  Container,
  Flex,
  HStack,
  Heading,
  IconButton,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { FaGithub } from 'react-icons/fa';

function Footer() {
  const colorMode = useColorModeValue('light', 'dark');

  return (
    <>
      <Container
        as="footer"
        role="contentinfo"
        py={{ base: '6', md: '8' }}
        backgroundColor={colorMode === 'light' ? 'gray.50' : 'gray.700'}
        position={'fixed'}
        bottom={'0'}
        minWidth={'100%'}
      >
        <Stack spacing={{ base: '4', md: '5' }}>
          {/* <Stack justify="space-between" direction="row" align="center">
                <Heading fontSize="sm" fontWeight="semibold">
                    giftmaker
                </Heading>
                </Stack> */}
          <HStack
            color={colorMode === 'light' ? 'gray.600' : 'gray.400'}
            spacing={6}
          >
            <Link fontSize="sm" href="/privacy-policy">
              privacy
            </Link>
            <Link fontSize="sm" href="/terms-and-conditions">
              terms & conditions
            </Link>
            <Link fontSize="sm" href="/contact-us">
              contact us
            </Link>
          </HStack>
          <Text fontSize="xs" color={'gray.500'}>
            &copy; {new Date().getFullYear()} giftmaker. All rights reserved.
          </Text>
        </Stack>
      </Container>
    </>
  );
}

export default Footer;
